import {
  HttpClient,
  httpErrorHandler,
  AxiosHttpClient,
  API_URL,
} from "@newstart/core"
import { AuthToken } from "@newstart/auth"

class RankingService {
  constructor(private _httpClient: HttpClient) {}

  async getGeneral(token: AuthToken): Promise<GeneralRankingData> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<GeneralRankingData>("/me/ranking/general", {
          Authorization: `Bearer ${token}`,
        })
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }

  async getByAge(token: AuthToken): Promise<AgeRankingData> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<AgeRankingData>("/me/ranking/age", {
          Authorization: `Bearer ${token}`,
        })
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }

  async getByGender(token: AuthToken): Promise<GenderRankingData> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<GenderRankingData>("/me/ranking/gender", {
          Authorization: `Bearer ${token}`,
        })
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }

  async getByTeam(token: AuthToken): Promise<TeamRankingData> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<TeamRankingData>("/me/ranking/team", {
          Authorization: `Bearer ${token}`,
        })
        .then(resolve)
        .catch((error) => httpErrorHandler(error, reject))
    })
  }

  // async getData(token: AuthToken): Promise<RankingData> {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient
  //       .get<RankingData>("/me/ranking", {
  //         Authorization: `Bearer ${token}`,
  //       })
  //       .then(resolve)
  //       .catch((error) => httpErrorHandler(error, reject))
  //   })
  // }
}

export const getRankingService = () => {
  return new RankingService(new AxiosHttpClient(API_URL))
}

export type GeneralRankingData = {
  general: GeneralRankingEntry[]
}

export type AgeRankingData = {
  userAgeGroup: string
  byAge: IndividualRankingEntry[]
}

export type GenderRankingData = {
  userGender: "M" | "F"
  byGender: IndividualRankingEntry[]
}

export type TeamRankingData = {
  teams: {
    general: TeamRankingEntry[]
    byMyTeam: GeneralRankingEntry[]
    byTeamSize: TeamRankingEntry[]
    teamSize: number
  }
}

export type IndividualRankingEntry = {
  _sum: {
    points: number
  }
  userId: string
  user?: {
    id: string
    firstName: string
    lastName: string
    photoUrl: string
    username: string
    points: number
  }
}

export type GeneralRankingEntry = {
  id: string
  points: number
  firstName: string
  lastName: string
  username: string
  teamId: string
}

export type TeamRankingEntry = {
  id: string
  name: string
  members: number
  points: number
}
