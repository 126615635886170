import React from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { BaseModalContainer, StatusBar } from "@newstart/ui"

import { GuidesStackParamList } from "../navigation"
import { StyledBody, StyledText, StyledSubtitle } from "../components"
import { Image, View } from "react-native"
import moment from "moment"
import { useEngine } from "@newstart/engine"

type Props = NativeStackScreenProps<GuidesStackParamList, "Guides_Faq_Modal">

export const GuideFaqModal: React.FunctionComponent<Props> = ({
  navigation,
}) => {
  const { startDate } = useEngine()
  return (
    <BaseModalContainer onClose={() => navigation.goBack()} title="FAQ">
      <StatusBar style="auto" />

      <StyledBody>
        <StyledText>
          Embarque pour un voyage de remise en forme de 80 jours, par équipe de
          7 amis/collègues, avec le programme NewStart 80.
        </StyledText>
        <StyledSubtitle>NewStart 80, qu’est-ce que c’est ?</StyledSubtitle>
        <StyledText>
          Ce programme de remise en forme et de santé globale t’aidera à adopter
          un nouveau style de vie pour plus de bien-être, à travers des
          activités physiques régulières, de meilleurs choix nutritionnels, un
          sommeil de qualité et un équilibre intérieur renouvelé. Simple et
          ludique, ce programme favorise un esprit d’équipe, source de soutien
          et d’encouragement pour créer de nouvelles habitudes de vie, en
          douceur et durablement.
        </StyledText>
        <StyledSubtitle>
          Quel est l’objectif du programme NewStart 80 ?
        </StyledSubtitle>
        <StyledText>
          Le premier objectif de NewStart 80 est de te faire marcher !
          Car marcher c’est la santé.  Sachant que nous faisons en moyenne 5 000
          pas par jour, nous te proposons de marcher au minimum 10 000 pas par
          jour pendant 80 jours. S’ajoutent à cela d’autres “bonnes
          habitudes” spécifiques à acquérir au long du programme pour améliorer
          ta qualité de vie. En somme, plus tu fais de pas et plus tu réussis
          les défis “bonnes habitudes”, plus ton équipe et toi-même avez de
          chances d’être dans le haut du tableau et surtout de gagner en qualité
          de vie.
        </StyledText>
        <StyledSubtitle>Comment comptabiliser mes pas ?</StyledSubtitle>
        <StyledText>
          Tu as besoin d’un podomètre, appareil qui compte les pas que tu
          accomplis pendant la journée. Il te servira pour la marche et pour le
          jogging. C’est le seul matériel indispensable à ce programme de
          santé.  Grâce au podomètre, tu pourras alors inscrire chaque jour le
          nombre de tes pas dans ton tableau de bord NewStart 80, ce qui te
          permettra d’observer ton évolution personnelle, l’évolution de ton
          équipe, mais aussi l’évolution des autres équipes.
        </StyledText>
        <StyledSubtitle>
          La marche seule  est-elle comptabilisée ?
        </StyledSubtitle>
        <StyledText>
          Non. Il est également possible de faire du vélo, de la raquette à
          neige, de la natation, du fitness, etc. Le barème de comptage de « pas
          » est le suivant pour ces activités.
        </StyledText>
        <StyledSubtitle variant="h2">BAREME DES SPORTS</StyledSubtitle>
        <StyledText>
          Attention les différents dénivelés positifs sont basés sur une
          distance approximative de 7 km ou  de 10 000 pas. 
        </StyledText>
        <View
          style={{
            width: 300,
            height: 250,
            aspectRatio: 1 * 1.4,
          }}
        >
          <Image
            style={{
              resizeMode: "cover",
              width: "100%",
              height: "100%",
            }}
            source={require("@newstart/assets/img/faq-graphic.png")}
          />
        </View>
        <StyledSubtitle>Qui peut participer ?</StyledSubtitle>
        <StyledText>
          Ce programme est ouvert à toutes et à tous ! Toutefois, comprends bien
          qu’il s’agit d’un programme d’effort physique et de défis liés au
          style de vie. Il faut donc être dans des conditions permettant de
          participer de façon convenable et sans risque. Commence
          progressivement. En cas de doute, demande l’avis de ton médecin.
        </StyledText>
        <StyledSubtitle>Combien cela coûte-t-il ?</StyledSubtitle>
        <StyledText>
          Ce programme est totalement gratuit. Sa mise en place est organisée
          par la Ligue Vie et Santé de Suisse Romande et du Tessin.
        </StyledText>
        <StyledSubtitle>
          Quand démarre le programme et combien de temps dure-t-il ?
        </StyledSubtitle>
        <StyledText>
          Le programme NewStart 80 commence le{" "}
          {moment(startDate).format("DD MMMM YYYY")} pour une durée de 80 jours,
          d’où le nom NewStart 80 !
        </StyledText>
        <StyledSubtitle>
          Je veux participer : comment m’inscrire ?
        </StyledSubtitle>
        <StyledText>
          Télécharge dès maintenant l’application NewStart 80 dans Apple Store
          ou Google play. Ou bien rendez-vous sur le site www.newstart80.ch. Par
          ces biais, tu crées ton profil et suite à cela, trois options
          s’offrent à toi.
        </StyledText>
        <StyledText>
          1- Tu crées une équipe de laquelle tu seras automatiquement le leader,
          et tu invites tes amis et connaissances pour compléter le groupe
          (jusqu’à 7 personnes).
        </StyledText>
        <StyledText>
          2- Tu rejoins une équipe déjà existante grâce à une invitation du
          leader de l’équipe. L’invitation est un code envoyé par e-mail ou par
          un autre service de messagerie.
        </StyledText>
        <StyledText>
          3- Tu rejoins le programme et automatiquement tu es le leader d’une
          équipe, mais tu n’invites personne. Tu vivras alors ce programme
          seul.e. Tu peux aussi inviter un nombre inférieur de participants que
          les 7 possibles. Toutefois, sache qu’en choisissant d’être seul.e ou
          d’avoir moins de participants dans ton équipe, tu diminues les chances
          d’être bien classé au classement en équipe.
        </StyledText>
        <StyledSubtitle>Quand débutent les inscriptions ?</StyledSubtitle>
        <StyledText>
          Il n’existe aucune démarche d’inscription officielle à faire auprès de
          la Ligue Vie et Santé Suisse. Il suffit de télécharger l’application
          ou de rejoindre le programme par son site internet
          (https://newstart80.com). Cela fait office d’inscription. Nous te
          recommandons vivement de le faire dès maintenant pour t’habituer et te
          familiariser avec le programme.
        </StyledText>
        <StyledSubtitle>
          Pourquoi vaut-il mieux participer avec une équipe ?
        </StyledSubtitle>
        <StyledText>
          Il t’est proposé de faire ce « voyage de 80 jours » avec une équipe
          composée de maximum 7 personnes. Ceci parce que la dynamique de groupe
          t’aidera à ne pas te sentir seul.e ! Le groupe te soutiendra et
          t’encouragera. Ne prends pas peur, il ne s’agit pas de voir tes
          co-équipiers tous les jours pendant 80 jours. Tout cela se fait de
          façon virtuelle. Toutefois, pendant les 80 jours, deux défis spéciaux
          t’inciteront à marcher avec ton équipe, si possible. Plus vous serez
          nombreux à ce rendez-vous, plus de points, toi et ton équipe,
          engrangerez.
        </StyledText>
        <StyledSubtitle>
          Puis-je changer d’équipe ou changer le nom de mon équipe en cours de
          programme ?
        </StyledSubtitle>
        <StyledText>
          Oui, sans problème. Chaque participant peut, à tout moment, demander à
          son leader de quitter l’équipe et créer la sienne ou en rejoindre une
          déjà existante (grâce au code envoyé par le chef de groupe). Le chef
          d’équipe, et lui seul, peut à tout moment changer le nom de l’équipe.
        </StyledText>
        <StyledSubtitle>
          Est-ce possible de communiquer entre membres d’une équipe via
          l’application ?
        </StyledSubtitle>
        <StyledText>
          Oui, tout à fait ! L’application inclut l’option de discuter entre
          membres d’une même équipe.
        </StyledText>
        <StyledSubtitle>
          Techniquement, comment cela fonctionne ?
        </StyledSubtitle>
        <StyledText>
          Chaque jour, rends-toi dans l’onglet « Habitudes » de l’application et
          indique (toutes les options sont possibles et cumulables) :
        </StyledText>
        <StyledText>Le nombre de pas réalisés,</StyledText>
        <StyledSubtitle>
          Le nombre kilomètres parcourus en vélo/natation,
        </StyledSubtitle>
        <StyledText>
          Le temps passé en faisant les autres activités sportives (celui-ci est
          automatiquement transformé en pas).
        </StyledText>
        <StyledText>
          Le comptage total se fait automatiquement. Cela crée un classement
          individuel et un autre par équipe.
        </StyledText>
        <StyledText>
          Même démarche pour les « Bonnes habitudes » tout au long des 80 jours.
          Par exemple, si tu réussis le défi du sommeil, il te suffit de cocher
          la bulle correspondante qui apparaît chaque jour dans l’onglet «
          Habitudes ».
        </StyledText>
        <StyledSubtitle>
          Comment le programme peut-il savoir si j’ai bien fait les pas que
          j’enregistre ?
        </StyledSubtitle>
        <StyledText>
          Il ne le peut pas ! Il y va de l’honnêteté de chacun. Ce programme se
          base sur la confiance en chaque personne qui participe. Nous espérons
          que chacun sera le plus honnête possible au moment de rentrer ses
          données.
        </StyledText>
        <StyledSubtitle>
          Et si j’oublie d’insérer le nombre de mes pas le jour même sur l’appli
          ?
        </StyledSubtitle>
        <StyledText>
          L’objectif est que tu insères tes données quotidiennement. À minuit,
          le compteur passe à la journée suivante. Mais si tu oublies, il suffit
          de cliquer sur l’onglet de la date oubliée et d’y introduire ce que tu
          as fait ce jour là. Par contre, en ce qui concerne les « Bonnes
          habitudes », il est impossible de revenir en arrière. Si tu ne les
          notifies pas le jour-même, tu rates les 1200 pas bonus du jour.
          Attention : les bonus « Bonnes habitudes » s’accumulant au fil des
          semaines, cela pourrait être une perte conséquente.
        </StyledText>
        <StyledSubtitle>
          Pour réussir, faut-il « bouger » tous les jours de la semaine ?
        </StyledSubtitle>
        <StyledText>
          Non, le samedi sera le jour de repos hebdomadaire. Aucun « pas » ne
          sera comptabilisé ce jour-là. Il sera d’ailleurs impossible de rentrer
          des données ce jour-là.
        </StyledText>
        <StyledSubtitle>
          Quel accompagnement aurais-je pendant ces 80 jours ?
        </StyledSubtitle>
        <StyledText>
          Dès que les défis spécifiques commencent, l’application ou le site
          font apparaître des informations et des conseils sous forme de vidéos
          en matière de nutrition, sommeil et bien-être. Des informations
          supplémentaires se trouvent dans l’onglet « Guides ».
        </StyledText>
        <StyledSubtitle>
          En quoi consistent les bonnes habitudes ?
        </StyledSubtitle>
        <StyledText>
          Alors que tu es invité-e à bouger pendant les 80 jours du programme
          NewStart 80, tu seras aussi « défié.e » à vivre de « bonnes
          habitudes » dans les domaines de l’alimentation, du sommeil et du
          bien-être. Si tu réussis « ces défis », des points bonus -
          comptabilisés en pas - seront ajoutés à ton compte. Depuis la date où
          « la bonne habitude » t’est proposée jusqu’à la fin de NewStart 80, tu
          recevras1 200 pas bonus quotidiennement. Pour plus de détails consulte
          le site.
        </StyledText>
        <StyledSubtitle>
          Alors, prêt.e pour améliorer ta santé de manière significative ?
        </StyledSubtitle>
        <StyledText>
          Motivé.e pour retrouver la forme et faire le plein d’énergie ?
          Décidé.e à perdre quelques kilos ? Alors, NewStart 80 est là pour
          t’aider !
        </StyledText>
      </StyledBody>
    </BaseModalContainer>
  )
}
