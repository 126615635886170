import React from "react"
import { View } from "react-native"
import {
  MaterialIcons,
  Feather,
  MaterialCommunityIcons,
} from "@expo/vector-icons"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { BaseContainer } from "@newstart/ui"

import { Title, Subtitle, ButtonsContainer } from "./styles"
import { GradientBtn } from "../../components"
import { AdminStackParamList } from "../../navigation"
import { getAdminService } from "../../services"
import { useAuth } from "@newstart/auth"
import converter from "json-2-csv"

import { Platform } from "react-native"
import { StorageAccessFramework as SAF } from "expo-file-system"
import * as FileSystem from "expo-file-system"
import * as Sharing from "expo-sharing"
import Toast from "react-native-root-toast"
import I18n from "i18n-js"

type Props = NativeStackScreenProps<AdminStackParamList, "Admin_Screen">

const adminService = getAdminService()

export const AdminScreen: React.FunctionComponent<Props> = ({ navigation }) => {
  const { token } = useAuth()
  return (
    <BaseContainer style={{ justifyContent: "space-around" }}>
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <MaterialIcons name="security" size={60} color="#857D98" />
        <Title>NewStart 80</Title>
        <Subtitle>Admin Control</Subtitle>
      </View>

      <ButtonsContainer>
        <GradientBtn
          title={I18n.t("settings")}
          icon={<MaterialIcons name="settings" size={30} color="#27252F" />}
          onPress={() => navigation.navigate("Admin_Settings_Screen")}
        />
        <GradientBtn
          title={I18n.t("send-notifications")}
          icon={
            <MaterialIcons name="notifications" size={30} color="#27252F" />
          }
          onPress={() => navigation.navigate("Admin_Send_Notification_Modal")}
        />
        <GradientBtn
          title={I18n.t("manage-users")}
          icon={<Feather name="users" size={28} color="#27252F" />}
          onPress={() => navigation.navigate("Admin_Users_List_Screen")}
        />
        <GradientBtn
          title={I18n.t("export-users")}
          icon={
            <MaterialCommunityIcons name="download" size={30} color="#27252F" />
          }
          onPress={async () => {
            const users = await adminService.listUsers(token || "")
            converter.json2csv(users, async (err, csv) => {
              try {
                const directoryUri = FileSystem.documentDirectory
                const fileUri = directoryUri + `users.csv`

                // Write the file to system
                FileSystem.writeAsStringAsync(fileUri, csv || "")

                if (Platform.OS === "ios") {
                  const UTI = "public.item"
                  await Sharing.shareAsync(fileUri, { UTI })
                  Toast.show("Fichier téléchargé", {
                    duration: Toast.durations.LONG,
                  })
                } else if (Platform.OS === "android") {
                  const downloadDir = SAF.getUriForDirectoryInRoot("Download")
                  const permission = await SAF.requestDirectoryPermissionsAsync(
                    downloadDir
                  )

                  if (!permission.granted) {
                    return false
                  }

                  const destinationUri = await SAF.createFileAsync(
                    permission.directoryUri,
                    "users.csv",
                    "text/csv"
                  )

                  await SAF.writeAsStringAsync(
                    destinationUri,
                    await SAF.readAsStringAsync(fileUri)
                  )
                  Toast.show("Fichier téléchargé", {
                    duration: Toast.durations.LONG,
                  })
                }
              } catch (error) {
                console.log(error)
              }
            })
          }}
        />
      </ButtonsContainer>
    </BaseContainer>
  )
}
