import { useGeneralRankingData } from "../../../../stats/hooks/useRankingData"
import { View } from "react-native"
import { RankingListItem } from "../../../../stats/components"
import { useAuth } from "@newstart/auth"
import { Loading } from "@newstart/ui"

export const StatsGeneral = () => {
  const { user } = useAuth()
  const { data, isFetching } = useGeneralRankingData()

  if (isFetching) {
    return <Loading dark />
  }

  return (
    <View style={{ paddingTop: 20 }}>
      {data.map((item, i) => (
        <RankingListItem
          key={item.id + item.firstName + item.lastName}
          place={i}
          name={item.firstName + " " + item.lastName}
          points={item.points}
          isMe={item.id === user?.id}
        />
      ))}
    </View>
  )
}
