import React, { useCallback } from "react"
import { ScreenHeader, StatusBar, SegmentedContainer } from "@newstart/ui"
import i18n from "i18n-js"

import { StatsScreenMe } from "./StatsScreenMe"
import { StatsScreenTeam } from "./StatsScreenTeam"
import { useRankingData } from "../../hooks"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { StatsStackParamList } from "../../navigation"

type OnTeamSelectParams = {
  teamId: string
  position: number
  name: string
  points: number
}

type StatsScreenProps = NativeStackScreenProps<
  StatsStackParamList,
  "Stats_Screen"
>

export const StatsScreen: React.FunctionComponent<StatsScreenProps> = ({
  navigation,
}) => {
  const handleTeamSelection = useCallback((args: OnTeamSelectParams) => {
    navigation.navigate("Stats_Team_Detail", { ...args })
  }, [])

  return (
    <>
      <StatusBar style="inverted" />
      <SegmentedContainer
        backgroundColor={["#155474", "#0C0A24"]}
        headingComponent={<ScreenHeader title={i18n.t("tab-stats")} />}
        tabs={[i18n.t("tab-me"), i18n.t("tab-team")]}
        childrenOne={<StatsScreenMe />}
        childrenTwo={
          <StatsScreenTeam
            // isFetching={isFetching}
            // general={teamGeneral}
            // byMyTeam={byMyTeam}
            // byTeamSize={byTeamSize}
            // teamSize={teamSize}
            onTeamSelect={handleTeamSelection}
          />
        }
        childrenThree={<></>}
        onRefresh={() => {}}
        safeArea
      />
    </>
  )
}
