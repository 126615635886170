import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import {
  AdminScreen,
  SettingsScreen,
  SendNotificationModal,
  UsersListScreen,
  UserDetailModal,
} from "../screens"
import { UserListItem } from "../services"
import { ui } from "@newstart/ui"
import { RootSiblingParent } from "react-native-root-siblings"
import I18n from "i18n-js"

export type AdminStackParamList = {
  Admin_Screen: undefined
  Admin_Settings_Screen: undefined
  Admin_Users_List_Screen: undefined
  Admin_User_Detail_Modal: { user: UserListItem }
  Admin_Send_Notification_Modal: undefined
}

const Stack = createNativeStackNavigator<AdminStackParamList>()

export const AdminNavigator = () => {
  return (
    <RootSiblingParent>
      <Stack.Navigator initialRouteName="Admin_Screen">
        <Stack.Group screenOptions={{ headerShown: false }}>
          <Stack.Screen name="Admin_Screen" component={AdminScreen} />
        </Stack.Group>
        <Stack.Group
          screenOptions={{
            headerBackTitleVisible: false,
            headerTintColor: ui.colors.secondary,
            headerTitle: I18n.t("settings"),
            headerStyle: { backgroundColor: ui.colors.light },
          }}
        >
          <Stack.Screen
            name="Admin_Settings_Screen"
            component={SettingsScreen}
          />
        </Stack.Group>
        <Stack.Group
          screenOptions={{
            headerBackTitleVisible: false,
            headerTintColor: ui.colors.secondary,
            headerTitle: I18n.t("manage-users"),
            headerStyle: { backgroundColor: ui.colors.light },
          }}
        >
          <Stack.Screen
            name="Admin_Users_List_Screen"
            component={UsersListScreen}
          />
        </Stack.Group>
        <Stack.Group
          screenOptions={{ presentation: "modal", headerShown: false }}
        >
          <Stack.Screen
            name="Admin_User_Detail_Modal"
            component={UserDetailModal}
          />
          <Stack.Screen
            name="Admin_Send_Notification_Modal"
            component={SendNotificationModal}
          />
        </Stack.Group>
      </Stack.Navigator>
    </RootSiblingParent>
  )
}
