import { useAuth } from "@newstart/auth"
import i18n from "i18n-js"
import { BlackButton, Form, Input, StyledError, useMessage } from "@newstart/ui"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { ErrorMessage, Formik } from "formik"
import I18n from "i18n-js"
import { AdminStackParamList } from "../../../admin/navigation"
import { getAdminService } from "../../../admin/services"
import { useEffect, useState } from "react"
import { Button, Text } from "react-native"
import * as Yup from "yup"
import { StyledContainer } from "../UsersListScreen/styles"
import { DateInput } from "@newstart/ui/forms/DateInput"
import moment from "moment"
import { reloadAppAsync } from "expo"
import { BaseButton } from "@newstart/ui/buttons/BaseButton"
import {
  StyledSectionSubtitle,
  StyledSectionTitle,
} from "@newstart/ui/heading/SectionHeader/styles"
import { useActionSheet } from "@expo/react-native-action-sheet"
import { useEngine } from "@newstart/engine"

type Props = NativeStackScreenProps<
  AdminStackParamList,
  "Admin_Settings_Screen"
>

const adminService = getAdminService()

export const SettingsScreen: React.FunctionComponent<Props> = ({
  navigation,
}) => {
  const { token } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [settings, setSettings] = useState<any>({})

  const { currentIndex } = useEngine()

  const hasProgramStarted = currentIndex > 0 && currentIndex <= 80

  const { showActionSheetWithOptions } = useActionSheet()

  const { showError, showSuccess } = useMessage()

  useEffect(() => {
    if (!token) return
    ;(async () => {
      try {
        const settings = await adminService.getSettings(token)
        setSettings(settings)
        setIsLoading(false)
      } catch (error) {
        showError(error)
      }
    })()
  }, [token])

  const onSubmit = async (values: any) => {
    if (!token) return

    await adminService.updateSettings(
      {
        program: {
          ...settings.program,
          startDate: moment(values.start_date).format("YYYY-MM-DD"),
        },
        videoGuides: [values.yt_1, values.yt_2, values.yt_3],
      },
      token
    )
    showSuccess(I18n.t("settings_updated"))
    await reloadAppAsync()
  }

  // RESET BUTTONS
  const handleResetStats = () => {
    let options = [
      i18n.t("confirm"), // 0
      i18n.t("cancel"), // 1
    ]
    let destructiveButtonIndex = 0
    let cancelButtonIndex = 1

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        title:
          i18n.t("are-you-sure-stats") +
          (hasProgramStarted ? "\n⚠" + i18n.t("program-started") : ""),
        message: i18n.t("this-action-cannot-be-undone"),
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          try {
            console.log("ouais ouais")
            console.log("token", token)
            if (!token) return
            await adminService.resetStats(token)
            showSuccess("Les statistiques ont été supprimées")
          } catch (error) {
            showError(error)
          }
        }
      }
    )
  }

  const handleDeleteTeams = () => {
    let options = [
      i18n.t("confirm"), // 0
      i18n.t("cancel"), // 1
    ]
    let destructiveButtonIndex = 0
    let cancelButtonIndex = 1

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        title:
          i18n.t("are-you-sure-teams") +
          (hasProgramStarted ? "\n⚠" + i18n.t("program-started") : ""),
        message: i18n.t("this-action-cannot-be-undone"),
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          try {
            if (!token) return
            await adminService.deleteTeams(token)
            showSuccess("Les équipes ont été supprimées")
          } catch (error) {
            showError(error)
          }
        }
      }
    )
  }

  const handleDeleteUsers = () => {
    let options = [
      i18n.t("confirm"), // 0
      i18n.t("cancel"), // 1
    ]
    let destructiveButtonIndex = 0
    let cancelButtonIndex = 1

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        title:
          i18n.t("are-you-sure-users") +
          (hasProgramStarted ? "\n⚠" + i18n.t("program-started") : ""),
        message: i18n.t("this-action-cannot-be-undone"),
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          try {
            if (!token) return
            await adminService.deleteUsers(token)
            showSuccess("Les utilisateurs ont été supprimées")
          } catch (error) {
            showError(error)
          }
        }
      }
    )
  }

  if (isLoading) {
    return <Text>Loading....</Text>
  }

  return (
    <StyledContainer>
      <Form>
        <Formik
          initialValues={{
            start_date: new Date(settings.program.startDate),
            yt_1: settings.videoguides[0]?.videoUrl,
            yt_2: settings.videoguides[1]?.videoUrl,
            yt_3: settings.videoguides[2]?.videoUrl,
          }}
          validationSchema={Yup.object().shape({
            start_date: Yup.date().required("Required"),
            yt_1: Yup.string().url(),
            yt_2: Yup.string().url(),
            yt_3: Yup.string().url(),
          })}
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            values,
            isSubmitting,
          }) => (
            <>
              <DateInput
                label={I18n.t("start_date")}
                value={values.start_date}
                onChange={(_, date) => {
                  console.log("called", date)
                  setFieldValue("start_date", date)
                }}
                required
                error={
                  <ErrorMessage component={StyledError} name="start_date" />
                }
              />
              <Input
                label="Vidéo YouTube #1"
                onChangeText={handleChange("yt_1")}
                value={settings.videoguides[0]?.videoUrl}
                error={<ErrorMessage component={StyledError} name="yt_1" />}
              />
              <Input
                label="Vidéo YouTube #2"
                onChangeText={handleChange("yt_2")}
                value={settings.videoguides[1]?.videoUrl}
                error={<ErrorMessage component={StyledError} name="yt_2" />}
              />
              <Input
                label="Vidéo YouTube #3"
                onChangeText={handleChange("yt_3")}
                value={settings.videoguides[2]?.videoUrl}
                error={<ErrorMessage component={StyledError} name="yt_3" />}
              />
              <BlackButton
                onPress={handleSubmit}
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {I18n.t("save")}
              </BlackButton>
              <StyledSectionTitle style={{ marginTop: 50, marginBottom: 10 }}>
                Réinitialisations
              </StyledSectionTitle>
              <StyledSectionSubtitle style={{ marginBottom: 20 }}>
                Attention, ces actions de réinitialisation sont irréversibles.
              </StyledSectionSubtitle>
              <BaseButton textColor="red" onPress={handleResetStats}>
                Réinitialiser les statistiques
              </BaseButton>
              <BaseButton textColor="red" onPress={handleDeleteTeams}>
                Réinitialiser les équipes
              </BaseButton>
              <BaseButton textColor="red" onPress={handleDeleteUsers}>
                Réinitialiser les utilisateurs
              </BaseButton>
              <Button
                title="Press me"
                onPress={() => {
                  throw new Error("Hello, again, Sentry!")
                }}
              />
            </>
          )}
        </Formik>
      </Form>
    </StyledContainer>
  )
}
