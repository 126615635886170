import React from "react"
import { ScrollView, TouchableOpacity, View } from "react-native"
import { Loading } from "@newstart/ui"
import { TeamRankingEntry } from "@newstart/engine"

import { RankingListItem, Title } from "../../../components"
import { useAuth } from "@newstart/auth"
import i18n from "i18n-js"
import { useTeamRankingData } from "../../../../stats/hooks/useRankingData"

type OnTeamSelectParams = {
  teamId: string
  position: number
  name: string
  points: number
}

type StatsScreenTeamProps = {
  onTeamSelect: (params: OnTeamSelectParams) => void
}

export const StatsScreenTeam = ({ onTeamSelect }: StatsScreenTeamProps) => {
  const { user, team } = useAuth()

  if (!user || !team) return null

  const handleTeamDetails = (item: TeamRankingEntry & { place: number }) => {
    onTeamSelect({
      teamId: item.id,
      position: item.place,
      name: item.name,
      points: item.points,
    })
  }

  const { data, isFetching } = useTeamRankingData()

  if (isFetching) {
    return <Loading dark />
  }

  return (
    <ScrollView style={{ paddingTop: 20 }}>
      <Title>{i18n.t("my-team-ranking")}</Title>
      {data?.teams.byMyTeam.map((item, i) => (
        <RankingListItem
          key={item.id + item.firstName + item.lastName}
          place={i}
          name={item.firstName + " " + item.lastName}
          points={item.points}
          isMe={item.id === user?.id}
        />
      ))}
      <Title>{i18n.t("size-ranking", { number: data?.teams.teamSize })}</Title>
      {data?.teams.byTeamSize.map((item, i) => (
        <TouchableOpacity
          key={item.id + item.name}
          onPress={() => handleTeamDetails({ ...item, place: i })}
        >
          <RankingListItem
            place={i}
            name={item.name}
            points={item.points}
            isMe={item.id === user?.id}
          />
        </TouchableOpacity>
      ))}
      <Title>{i18n.t("general-ranking")}</Title>
      {data?.teams.general.map((item, i) => (
        <TouchableOpacity
          key={item.id + item.name}
          onPress={() => handleTeamDetails({ ...item, place: i })}
        >
          <RankingListItem
            key={item.id + item.name}
            place={i}
            name={item.name}
            points={item.points}
            isMe={item.id === user?.id}
          />
        </TouchableOpacity>
      ))}
    </ScrollView>
  )
}
