import React from "react"
import DateTimePicker, {
  DateTimePickerAndroid,
} from "@react-native-community/datetimepicker"
import { Platform } from "react-native"
import {
  StyledFakeInputText,
  StyledFormInputContainer,
  StyledLabel,
} from "./styles"

export type DateInputProps = React.ComponentProps<typeof DateTimePicker> & {
  label?: string
  required?: boolean
  error?: React.ReactNode
  dark?: boolean
}

export const DateInput: React.FunctionComponent<DateInputProps> = ({
  label,
  required,
  error,
  dark,
  ...props
}) => {
  const openAndroidDatePicker = () => {
    if (Platform.OS === "android") {
      DateTimePickerAndroid.open({
        value: props.value,
        onChange: props.onChange,
        mode: "date",
      })
    }
  }

  return (
    <StyledFormInputContainer>
      {label && (
        <StyledLabel dark={dark}>
          {label}
          {required && "*"}
        </StyledLabel>
      )}
      {Platform.OS === "android" ? (
        <StyledFakeInputText onPress={openAndroidDatePicker} dark={dark}>
          {props.value
            ? new Date(props.value).toLocaleDateString()
            : "Select date"}
        </StyledFakeInputText>
      ) : (
        <DateTimePicker mode="date" {...props} />
      )}
      {error}
    </StyledFormInputContainer>
  )
}
