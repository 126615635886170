import { useContext } from "react"
import { AuthContext } from "./AuthContext"
import JWT from "expo-jwt"

export const useAuth = () => {
  const context = useContext(AuthContext)

  // Redirect user to login screen if token is invalid
  if (!!context?.token?.length) {
    try {
      JWT.decode(context.token as string, null, { algorithm: "HS256" })
    } catch (e) {
      context.signOut()
    }
  }

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.")
  }

  return context
}
