import React, { useState } from "react"
import { ActivityIndicator, View, ViewProps } from "react-native"
import SegmentedControl from "@react-native-community/segmented-control"

import { ui } from "../../styles"
import { BaseContainer } from "./BaseContainer"

type Props = ViewProps & {
  backgroundColor?: string[] | string
  headingComponent: React.ReactNode
  tabs: string[]
  onRefresh: () => void
  childrenOne: React.ReactNode
  childrenTwo: React.ReactNode
  childrenThree: React.ReactNode
  safeArea?: boolean
}

export const SegmentedContainer: React.FunctionComponent<Props> = ({
  backgroundColor,
  headingComponent,
  tabs,
  onRefresh,
  childrenOne,
  childrenTwo,
  childrenThree,
  ...props
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <BaseContainer backgroundColor={backgroundColor} {...props}>
      {headingComponent}
      <SegmentedControl
        values={tabs}
        selectedIndex={selectedIndex}
        appearance="dark"
        onChange={(event) =>
          setSelectedIndex(event.nativeEvent.selectedSegmentIndex)
        }
        style={{
          height: 40,
          marginHorizontal: 20,
        }}
        activeFontStyle={{
          fontFamily: ui.fonts.Inter.Bold,
          fontSize: 16,
        }}
        fontStyle={{
          fontFamily: ui.fonts.Inter.Bold,
          fontSize: 16,
        }}
      />
      {selectedIndex === 0 && <View style={{ flex: 1 }}>{childrenOne}</View>}
      {selectedIndex === 1 && <View style={{ flex: 1 }}>{childrenTwo}</View>}
      {selectedIndex === 2 && <View style={{ flex: 1 }}>{childrenThree}</View>}
    </BaseContainer>
  )
}

const Loading: React.FC = () => {
  return (
    <View
      style={{
        flex: 0.21,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 40,
      }}
    >
      <ActivityIndicator size="large" color={ui.colors.primary} />
    </View>
  )
}
