import React from "react"
import { StyleSheet, Image, View, Platform } from "react-native"
import { MaterialIcons } from "@expo/vector-icons"
import { ui } from "@newstart/ui"
import { A } from "@expo/html-elements"

const { width, maxWidth } = ui

const ITEM_WIDTH = maxWidth
const ITEM_HEIGHT = maxWidth * (9 / 16)

export type CarouselItemProps = {
  videoUrl: string
  background: string
}

export const CarouselItem: React.FunctionComponent<CarouselItemProps> = ({
  videoUrl,
  background,
}) => {
  return (
    <A href={videoUrl} style={styles.cardView} target="_blank">
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MaterialIcons
          style={styles.playIcon}
          name="play-circle-outline"
          size={60}
          color="#FFF"
        />
        {Platform.OS === "web" ? (
          <img style={styles.image} src={background} />
        ) : (
          <Image style={styles.image} src={background} />
        )}
      </View>
    </A>
  )
}

const styles = StyleSheet.create({
  cardView: {
    width: ITEM_WIDTH,
    maxWidth: maxWidth,
    height: ITEM_HEIGHT,
  },
  playIcon: {
    position: "absolute",
    left: (ITEM_WIDTH > maxWidth ? maxWidth : ITEM_WIDTH) / 2 - 30,
    bottom: ITEM_HEIGHT / 2 - 30,
    zIndex: 1,
  },
  image: {
    width: ITEM_WIDTH,
    maxWidth: maxWidth,
    height: ITEM_HEIGHT,
    borderRadius: 10,
  },
  // textView: {
  //   position: 'absolute',
  //   bottom: 10,
  //   margin: 10,
  //   left: 5,
  // },
  // itemTitle: {
  //   color: 'white',
  //   fontSize: 22,
  //   shadowColor: '#000',
  //   shadowOffset: { width: 0.8, height: 0.8 },
  //   shadowOpacity: 1,
  //   shadowRadius: 3,
  //   marginBottom: 5,
  //   fontWeight: "bold",
  //   elevation: 5
  // },
  // itemDescription: {
  //   color: 'white',
  //   fontSize: 12,
  //   shadowColor: '#000',
  //   shadowOffset: { width: 0.8, height: 0.8 },
  //   shadowOpacity: 1,
  //   shadowRadius: 3,
  //   elevation: 5
  // }
})
