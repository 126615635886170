import React from "react"
import { ScrollView } from "react-native"
import i18n from "i18n-js"
import { ScreenSection, SegmentedContainer } from "@newstart/ui"
import { useAuth } from "@newstart/auth"

import { MultipleLinesChart } from "../../../components"
import { StatsGeneral } from "./StatsGeneral"
import { StatsAge } from "./StatsAge"
import { StatsGender } from "./StatsGender"

export const StatsScreenMe: React.FunctionComponent = () => {
  const { user } = useAuth()
  if (!user) return null

  return (
    <ScrollView>
      <ScreenSection title={i18n.t("activity")}>
        <MultipleLinesChart />
      </ScreenSection>
      <SegmentedContainer
        backgroundColor={["transparent", "transparent"]}
        headingComponent={<></>}
        tabs={["Général", "Par age", "Par genre"]}
        childrenOne={<StatsGeneral />}
        childrenTwo={<StatsAge />}
        childrenThree={<StatsGender />}
        onRefresh={() => {}}
        safeArea
      />
    </ScrollView>
  )
}
