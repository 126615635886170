import React, { PropsWithChildren } from "react"

import { TitleContainer, Label, LabelBig } from "./styles"

export const Title: React.FunctionComponent<
  PropsWithChildren<{
    big?: boolean
    rightAction?: React.ReactNode
  }>
> = ({ big, children, rightAction, ...props }) => {
  return (
    <TitleContainer {...props}>
      {big ? <LabelBig>{children}</LabelBig> : <Label>{children}</Label>}
    </TitleContainer>
  )
}
