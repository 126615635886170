import { useAuth } from "@newstart/auth"
import {
  getRankingService,
  GeneralRankingEntry,
  IndividualRankingEntry,
  TeamRankingEntry,
} from "@newstart/engine"
import { useMessage } from "@newstart/ui"
import {
  AgeRankingData,
  GenderRankingData,
  TeamRankingData,
} from "modules/engine/services/ranking/ranking-service"
import { useEffect, useState } from "react"

const rankingService = getRankingService()

export const useGeneralRankingData = () => {
  const { token } = useAuth()
  const { showError } = useMessage()

  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState<GeneralRankingEntry[]>([])

  useEffect(() => {
    ;(async () => {
      if (!token) return
      try {
        const { general } = await rankingService.getGeneral(token)
        setData(general)
      } catch (error) {
        showError(error)
      } finally {
        setIsFetching(false)
      }
    })()
  }, [token])

  return { data, isFetching }
}

export const useAgeRankingData = () => {
  const { token } = useAuth()
  const { showError } = useMessage()

  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState<AgeRankingData>()

  useEffect(() => {
    ;(async () => {
      if (!token) return
      try {
        const byAge = await rankingService.getByAge(token)
        setData(byAge)
      } catch (error) {
        showError(error)
      } finally {
        setIsFetching(false)
      }
    })()
  }, [token])

  return { data, isFetching }
}

export const useGenderRankingData = () => {
  const { token } = useAuth()
  const { showError } = useMessage()

  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState<GenderRankingData>()

  useEffect(() => {
    ;(async () => {
      if (!token) return
      try {
        const byGender = await rankingService.getByGender(token)
        setData(byGender)
      } catch (error) {
        showError(error)
      } finally {
        setIsFetching(false)
      }
    })()
  }, [token])

  return { data, isFetching }
}

export const useTeamRankingData = () => {
  const { token } = useAuth()
  const { showError } = useMessage()

  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState<TeamRankingData>()

  useEffect(() => {
    ;(async () => {
      if (!token) return
      try {
        const byTeam = await rankingService.getByTeam(token)
        setData(byTeam)
      } catch (error) {
        showError(error)
      } finally {
        setIsFetching(false)
      }
    })()
  }, [token])

  return { data, isFetching }
}
