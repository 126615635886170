import styled from "styled-components/native"
import { LinearGradient } from "expo-linear-gradient"
import { Entypo } from "@expo/vector-icons"
import { ui } from "@newstart/ui"
const { fonts, maxWidth } = ui

export const StyledButtonContainer = styled.TouchableOpacity`
  width: 100%;
  max-width: ${maxWidth}px;
  border-radius: 40px;
  overflow: hidden;
  margin: 10px 0;
`

export const StyledButtonBackground = styled(LinearGradient).attrs({
  start: [0, 1],
  end: [1, 0],
})`
  padding: 14px 18px;
  flex-direction: row;
  align-items: center;
`

export const StyledLabel = styled.Text<{ color: string }>`
  font-family: ${fonts.Inter.Bold};
  font-size: 16px;
  color: ${(props) => props.color};
  letter-spacing: -0.55px;
`

export const StyledIconContainer = styled.View<{ color?: string }>`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background-color: ${(props) =>
    props.color ? props.color : "rgba(255,255,255,0.4)"};
  margin-right: 10px;
  align-items: center;
  justify-content: center;
`

export const StyledArrow = styled(Entypo).attrs({
  name: "chevron-right",
  size: 26,
})`
  margin-left: auto;
`
