import React, { useCallback, useState } from "react"
import { Share, TouchableOpacity, TouchableOpacityProps } from "react-native"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { Ionicons, SimpleLineIcons } from "@expo/vector-icons"
import i18n from "i18n-js"
import { useAuth } from "@newstart/auth"
import {
  BaseContainer,
  ScreenSection,
  StatusBar,
  useMessage,
} from "@newstart/ui"

import { HistoryList, ProfileHeader } from "../../components"
import { ProfileStackParamList } from "../../navigation"
import { StyledNavigationHeader } from "./styles"
import { useEngine } from "@newstart/engine"

type ProfileModalProps = NativeStackScreenProps<
  ProfileStackParamList,
  "Profile_Screen"
>

export const ProfileScreen: React.FunctionComponent<ProfileModalProps> = ({
  navigation,
}) => {
  const { user } = useAuth()
  if (!user) return null

  const { showError } = useMessage()
  const { userTotalPoints, teamTotalPoints } = useEngine()

  const userPoints =
    userTotalPoints > 0
      ? i18n.toNumber(userTotalPoints, { precision: 0, delimiter: " " })
      : "--"

  const userTeamPoints =
    teamTotalPoints > 0
      ? i18n.toNumber(teamTotalPoints, { precision: 0, delimiter: " " })
      : "--"

  const handleSharing = useCallback(async () => {
    try {
      await Share.share({
        message: i18n.t("invitate-message", { code: user.team?.inviteCode }),
      })
    } catch (error) {
      showError(error)
    }
  }, [user.team, showError])

  return (
    <BaseContainer
      backgroundImage={require("@newstart/assets/img/bg-profile.png")}
    >
      <StatusBar style="light" />
      <StyledNavigationHeader>
        <NotificationButton
          onPress={() => {
            navigation.navigate("Profile_Notifications_Screen")
          }}
        />
        <SettingsButton
          onPress={() => {
            navigation.navigate("Profile_Settings_Screen")
          }}
        />
      </StyledNavigationHeader>

      <ProfileHeader
        userPoints={userPoints}
        userTeamPoints={userTeamPoints}
        onRequestShare={handleSharing}
      />

      <ScreenSection title={i18n.t("history")}>
        <HistoryList />
      </ScreenSection>
    </BaseContainer>
  )
}

const SettingsButton = (props: TouchableOpacityProps) => {
  return (
    <TouchableOpacity {...props}>
      <SimpleLineIcons
        name="settings"
        size={24}
        color="#FFF"
        style={{ marginRight: 20 }}
      />
    </TouchableOpacity>
  )
}

const NotificationButton = (props: TouchableOpacityProps) => {
  return (
    <TouchableOpacity {...props}>
      <Ionicons
        name="notifications"
        size={24}
        color="#FFF"
        style={{ marginRight: 20 }}
      />
    </TouchableOpacity>
  )
}
