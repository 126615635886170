import React from "react"
import i18n from "i18n-js"

import {
  StyledEmptyContainer,
  StyledBubbleInner,
  StyledBubble,
  StyledGraphic,
  StyledTitle,
  StyledSubtitle,
} from "./styles"
import { BaseContainer, ScreenHeader, StatusBar } from "@newstart/ui"
import { useEngine } from "@newstart/engine"
import moment from "moment"

export const HabitsScreenFinished = () => {
  const { startDate } = useEngine()

  return (
    <BaseContainer backgroundColor={["#30A5DA", "#0D00B1"]} safeArea>
      <ScreenHeader title="NewStart 80" />
      <StyledEmptyContainer>
        <StyledEmptyContainer>
          <StyledBubbleInner>
            <StyledGraphic
              source={require("@newstart/assets/img/sabbath-card-icon.png")}
            />
            <StyledTitle>{i18n.t("endgame-message-title")}</StyledTitle>
            <StyledSubtitle>
              {i18n.t("endgame-message-subtitle", {
                nextYear: moment(startDate).add(1, "year").get("year"),
              })}
            </StyledSubtitle>
          </StyledBubbleInner>
          <StyledBubble />
        </StyledEmptyContainer>
      </StyledEmptyContainer>
      <StatusBar style="inverted" />
    </BaseContainer>
  )
}
