import React, { useCallback, useEffect } from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useIsFocused } from "@react-navigation/native"
import {
  connectActionSheet,
  useActionSheet,
} from "@expo/react-native-action-sheet"
import i18n from "i18n-js"
import { StatusBar, useMessage, BaseContainer, ui } from "@newstart/ui"
import { useAuth } from "@newstart/auth"

import { TeamStackParamList } from "../../navigation"
import { TeamScreenHeader } from "../../components"
import { TeamWithMembersScreen } from "./TeamWithMembersScreen"
import { TeamEmptyScreen } from "../TeamEmptyScreen"

type TeamScreenProps = NativeStackScreenProps<TeamStackParamList, "Team_Screen">

export const TeamScreen: React.FunctionComponent<TeamScreenProps> =
  connectActionSheet(({ navigation }) => {
    const { leaveTeam, deleteTeam, team, user, reloadUser } = useAuth()
    const { showError } = useMessage()
    const isFocused = useIsFocused()
    const { showActionSheetWithOptions } = useActionSheet()

    const isOwner = team?.ownerId === user?.id
    const lengthOfMembers = team?.members?.length || 0

    const handleTeamSettings = useCallback(() => {
      const options = isOwner
        ? [
            i18n.t("edit-team-name"), // 0
            i18n.t("leave-team"), // 1
            i18n.t("delete-team"), // 2
            i18n.t("cancel"), // 3
          ]
        : [
            i18n.t("edit-team-name"), // 0
            i18n.t("leave-team"), // 1
            i18n.t("cancel"), // 2
          ]
      const destructiveButtonIndex = isOwner ? [1, 2] : [1]
      const cancelButtonIndex = isOwner ? 3 : 2

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
          destructiveButtonIndex,
        },
        async (buttonIndex) => {
          if (buttonIndex === 0) {
            navigation.navigate("Team_Renaming_Modal")
          } else if (buttonIndex === 1) {
            await handleLeaveTeam()
          } else if (buttonIndex === 2 && isOwner) {
            await handleDeleteTeam()
          }
        }
      )
    }, [isOwner, showActionSheetWithOptions])

    const handleLeaveTeam = useCallback(() => {
      const options = [
        i18n.t("confirm"), // 0
        i18n.t("cancel"), // 1
      ]
      const destructiveButtonIndex = 0
      const cancelButtonIndex = 1

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
          destructiveButtonIndex,
          title: i18n.t("are-you-sure"),
          message: i18n.t("this-action-cannot-be-undone"),
        },
        async (buttonIndex) => {
          if (buttonIndex === 0) {
            // Confirm
            try {
              await leaveTeam()
            } catch (error) {
              showError(error)
            }
          }
        }
      )
    }, [leaveTeam, showError])

    const handleDeleteTeam = useCallback(() => {
      let options = [
        i18n.t("confirm"), // 0
        i18n.t("cancel"), // 1
      ]
      let destructiveButtonIndex = 0
      let cancelButtonIndex = 1

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
          destructiveButtonIndex,
          title: i18n.t("are-you-sure"),
          message: i18n.t("this-action-cannot-be-undone"),
        },
        async (buttonIndex) => {
          if (buttonIndex === 0) {
            // Confirm
            if (!team) return
            try {
              await deleteTeam(team.id)
            } catch (error) {
              showError(error)
            }
          }
        }
      )
    }, [team, showError, deleteTeam])

    useEffect(() => {
      if (isFocused) {
        ;(async function reloadUserAndTeam() {
          await reloadUser()
        })()
      }
    }, [isFocused])

    return (
      <>
        <StatusBar style="inverted" />
        <BaseContainer backgroundColor={["#4E3980", "#2A1165"]} safeArea>
          <TeamScreenHeader
            title={team?.name ?? i18n.t("tab-team")}
            onOpenMembersModal={() => navigation.navigate("Team_Members_Modal")}
            onOpenSettings={handleTeamSettings}
          />

          {lengthOfMembers > 1 ? (
            <TeamWithMembersScreen />
          ) : (
            <TeamEmptyScreen />
          )}
        </BaseContainer>
      </>
    )
  })
