import styled from "styled-components/native"
import { Platform } from "react-native"
import { ui } from "../styles"
const { fonts, colors } = ui

export const StyledFormContainer = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingBottom: Platform.OS === "web" ? 0 : 400,
  },
})`
  flex: 1;
  width: 100%;
  max-width: 460px;
  padding: 20px 30px;
`

export const StyledFormInputContainer = styled.View`
  width: 100%;
  padding-bottom: 14px;
`

export const StyledLabel = styled.Text<{ dark?: boolean }>`
  font-family: ${fonts.Inter.Regular};
  font-size: 16px;
  color: ${(props) =>
    props.dark ? "rgba(255,255,255,0.6)" : colors.secondary};
  letter-spacing: -0.3px;
  margin-bottom: 8px;
`

export const StyledInputText = styled.TextInput<{ dark?: boolean }>`
  padding: 14px 18px;
  background-color: ${(props) =>
    props.dark ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.05)"};
  border-radius: 12px;
  font-family: ${fonts.Inter.Regular};
  font-size: 17px;
  color: ${(props) => (props.dark ? colors.light : colors.dark)};
`

export const StyledFakeInputText = styled.Text<{ dark?: boolean }>`
  padding: 14px 18px;
  background-color: ${(props) =>
    props.dark ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.05)"};
  border-radius: 12px;
  font-family: ${fonts.Inter.Regular};
  font-size: 17px;
  color: ${(props) => (props.dark ? colors.light : colors.dark)};
`

export const StyledError = styled.Text`
  font-family: ${fonts.Inter.SemiBold};
  font-size: 14px;
  color: red;
  letter-spacing: -0.3px;
  margin-top: 8px;
  margin-bottom: 8px;
`
