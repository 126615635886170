import { useAgeRankingData } from "../../../../stats/hooks/useRankingData"
import { View } from "react-native"
import { RankingListItem, Title } from "../../../../stats/components"
import { useAuth } from "@newstart/auth"
import { Loading } from "@newstart/ui"
import I18n from "i18n-js"

export const StatsAge = () => {
  const { user } = useAuth()
  const { data, isFetching } = useAgeRankingData()

  console.log(data?.byAge[0])

  if (isFetching) {
    return <Loading dark />
  }

  return (
    <View>
      <Title>
        {data?.userAgeGroup} {I18n.t("age-ranking")}
      </Title>
      {data?.byAge.map((item, i) => (
        <RankingListItem
          key={item.userId}
          place={i}
          name={item.user?.username ?? ""}
          points={item._sum.points}
          isMe={item.userId === user?.id}
        />
      ))}
    </View>
  )
}
