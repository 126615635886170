import React from "react"
import i18n from "i18n-js"
import * as Application from "expo-application"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useAuth } from "@newstart/auth"
import { ProfileStackParamList } from "../../navigation"
import {
  StyledBtn,
  StyledBtnLabel,
  StyledAboutMessage,
  StyledLogo,
  StyledCopyright,
  StyledBold,
  StyledVersion,
} from "./styles"
import { BaseContainer, StatusBar, useMessage } from "@newstart/ui"
import { Text } from "react-native"
import { useActionSheet } from "@expo/react-native-action-sheet"

type SettingsScreenProps = NativeStackScreenProps<
  ProfileStackParamList,
  "Profile_Settings_Screen"
>

export const SettingsScreen: React.FunctionComponent<SettingsScreenProps> = ({
  navigation,
}) => {
  const { showActionSheetWithOptions } = useActionSheet()
  const { showError, showSuccess } = useMessage()
  const { signOut, deleteUser, user } = useAuth()
  if (!user) return null

  const handleDeletingUser = () => {
    let options = [
      i18n.t("confirm"), // 0
      i18n.t("cancel"), // 1
    ]
    let destructiveButtonIndex = 0
    let cancelButtonIndex = 1

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
        title: i18n.t("are-you-sure"),
        message: i18n.t("this-action-cannot-be-undone"),
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          try {
            await deleteUser(user.id)
            await signOut()
            showSuccess("Votre compte a été supprimé")
          } catch (error) {
            showError(error)
          }
        }
      }
    )
  }

  return (
    <BaseContainer style={{ alignItems: "center" }}>
      <StatusBar style="auto" />
      <StyledBtn
        onPress={() => navigation.replace("Profile_Editing_Modal")}
        style={{ marginBottom: 20 }}
      >
        <StyledBtnLabel>{i18n.t("edit-profile")}</StyledBtnLabel>
      </StyledBtn>

      <StyledBtn onPress={signOut} style={{ marginBottom: 20 }}>
        <StyledBtnLabel>{i18n.t("sign-out")}</StyledBtnLabel>
      </StyledBtn>

      <StyledBtn onPress={handleDeletingUser}>
        <StyledBtnLabel style={{ color: "red" }}>
          {i18n.t("delete-account")}
        </StyledBtnLabel>
      </StyledBtn>

      <Text style={{ marginTop: 10 }}>
        {i18n.t("you-are-logged-in-as")} {user.firstName} {user.lastName}
      </Text>

      <StyledAboutMessage>
        <StyledLogo source={require("@newstart/assets/img/logos.png")} />
        <StyledCopyright>
          © {new Date().getFullYear()} Ligue Vie et Santé et Espoir Médias
        </StyledCopyright>
        <StyledCopyright>
          {i18n.t("developed-by")}{" "}
          <StyledBold>Matias Palheiro & Fayçal Hammoudi</StyledBold>
        </StyledCopyright>
        <StyledVersion>(2.2)</StyledVersion>
      </StyledAboutMessage>
    </BaseContainer>
  )
}
